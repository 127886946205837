import axios from 'axios'

const apiCient = axios.create({
  baseURL: '/api/v4',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  getProgress(gid) {
    return apiCient.post('/progress', { gid })
  },
  getChallengeList(gid) {
    return apiCient.post('/challenge/list', { gid })
  },
  getStatDaily(gid, date) {
    return apiCient.post('/stat/daily', { gid, date })
  },
  getStatMonthly(gid) {
    return apiCient.post('/stat/monthly', { gid })
  },
  getMember(gid) {
    return apiCient.post('/member', { gid })
  },
}
